@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  position: relative;
  overflow: hidden;
  padding: 0;
}

.section-inner {
  min-height: fn.spacing(68);
  display: grid;
  padding: fn.spacing(3) 0;
}

.item-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
}

.slider-tool-paper {
  box-shadow: none;
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.slider-tool-wrapper {
  width: 100%;
  margin: 0 0 fn.spacing(3) 0;
  max-width: 425px;
  padding: fn.spacing(3) fn.spacing(4);
  background-color: var.$color-neutral-0;
  border-radius: fn.spacing(1.5);
  box-shadow: 0 3.3px 18px -3px rgb(16 24 40 / 9%), 0 5px 6px -2px rgb(16 24 40 / 4%);
}

.slider-tool-value {
  color: var.$color-blue-600;
  text-align: center;
  padding-bottom: fn.spacing(1);
}

.slider-title {
  text-align: center;
  padding-bottom: fn.spacing(2);
}

.slider-tool-slider {
  width: 100%;

  [class^='MuiSlider-thumb'] {
    height: fn.spacing(3);
    width: fn.spacing(3);
  }

  [class^='MuiSlider-track'] {
    height: 5px;
    border: 0;
  }

  [class^='MuiSlider-rail'] {
    opacity: 1;
    height: 5px;
    border: 0;
  }
}

.pd-item-list-text {
  line-height: 0;
  font-size: 15px;
}

.pd-item-list {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 4px;

    div {
      flex-wrap: nowrap;
    }
  }

  p {
    padding-left: fn.spacing(1);
  }

  svg {
    stroke: var.$color-green-600;
  }
}

.marks {
  display: none;
}

.slider-tool-min-mark {
  margin-left: fn.spacing(4.5);
}

.slider-tool-max-mark {
  margin-left: fn.spacing(-7.5);
}

.slider-tool-cta {
  margin-top: fn.spacing(2);
  min-width: max-content;

  span {
    background-color: var.$color-neutral-0;
    border-radius: 50%;

    svg {
      color: var.$color-blue-600;
    }
  }
}

/* Tablet (large or greater) styles */
@media screen and (min-width: 768px) {
  .section {
    padding: fn.spacing(2) 0;
  }

  .section-inner {
    padding: fn.spacing(6.25) 0;
  }
}
