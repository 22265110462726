/*
Importing into component.module.scss:

`@use styles/backgrounds` as bg;
  NOTE: Be sure to use @use and not @import since doing so increases the bundle
  size while @use only loads a file once

- Using a background:
  `background: bg.$color-neutral-background-medium;`
*/
@use 'styles/variables' as var;

$bg-gradient-blue-1: linear-gradient(16.76deg, #30f -58.13%, #3b6af7 89.44%);

$bg-gradient-purple-blue-1: linear-gradient(81.78deg, var.$color-purple-300 4.23%, #3b6af7 117.8%);

$bg-gradient-blue-2: linear-gradient(
  189.74deg,
  #30f 5.88%,
  var.$color-purple-300 125%,
  var.$color-blue-100 135.36%
);

$linear-bg-color-dc: linear-gradient(172.38deg, #30f 30.04%, #71b4ff 144.48%, #ddf3ff 169.51%);
