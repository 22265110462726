@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.trust-markers-item-image {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  img {
    object-fit: contain;
    margin-bottom: 8px;
  }
}

.trust-markers-container {
  .trust-markers-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: fn.spacing(6) 16px;
    text-align: center;

    .trust-markers-item-eyebrow {
      padding-bottom: 0;
    }

    .trust-markers-title {
      color: #30f;
      padding-bottom: 16px;
    }

    .trust-markers-eyebrow {
      margin-top: 8px;
      padding-bottom: 0;
    }

    .trust-markers-markers {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 425px;

      .trust-markers-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        padding-bottom: fn.spacing(6);
        border-bottom: 1px solid var.$color-neutral-300;

        .trust-markers-item-title {
          color: #30f;
          margin-top: 16px;
        }

        .trust-markers-item-subtitle {
          margin-top: 16px;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &[data-marker-style='black1'] {
      padding: 0 32px;

      .trust-markers-markers {
        .trust-markers-item {
          .trust-markers-item-title {
            margin-top: 0;
            color: #1d252f;
          }

          .trust-markers-item-subtitle {
            margin-top: 0;
          }
        }
      }
    }

    &[data-marker-style='blue1'] {
      padding: fn.spacing(6) 16px;

      .trust-markers-subtitle {
        color: #fff;
      }

      .trust-markers-markers {
        .trust-markers-item {
          padding-bottom: 32px;
          border-bottom: 1px solid #94bbfe;

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          .trust-markers-item-title {
            color: #fff;
          }

          .trust-markers-item-subtitle {
            margin-top: 0;
            color: #fff;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1024px) {
  .trust-markers-container {
    .trust-markers-content {
      .trust-markers-markers {
        flex-direction: row;
        width: auto;
        max-width: initial;

        .trust-markers-item {
          border-bottom: 0;
          border-right: 1px solid var.$color-neutral-300;
          padding: 0 fn.spacing(6);

          &:last-child {
            border-right: 0;
          }
        }
      }

      &[data-marker-style='blue1'] {
        padding: 64px 16px;

        .trust-markers-markers {
          .trust-markers-item {
            border-bottom: 0;
            border-right: 1px solid #94bbfe;
            padding-bottom: 0;

            .trust-markers-item-title {
              margin-top: 0;
            }
          }
        }
      }

      &[data-marker-style='black1'] {
        padding: 32px 0 64px;

        .trust-markers-markers {
          .trust-markers-item {
            border-right: 1px solid #c0cbd8;
            padding: 0 32px;
          }
        }
      }

      &[data-marker-style='black1'],
      &[data-marker-style='blue1'] {
        .trust-markers-markers {
          .trust-markers-item {
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: var.$breakpoint-xl) {
  .trust-markers-container {
    .trust-markers-content {
      &[data-marker-style='black1'] {
        .trust-markers-markers {
          .trust-markers-item {
            padding: 0 64px;

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
